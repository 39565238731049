import React, { useEffect, useRef, useState, useContext } from 'react'
import { BrowserRouter, useNavigate, Link, Route, Routes, Switch } from "react-router-dom";
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from 'sweetalert2'
import { Helmet } from 'react-helmet'
import Nav from '../components/Nav/Nav'
import Sidenav from '../components/Nav/Sidenav'
import Withdrawal from '../components/Withdrawal';


// balance: "0",
// deposits:"0",
// Claimed:"0",
// maxpayout:"0",
// referralearn: "0",

function Dashboard() {

    const [{ userdetails, loggedin, tradingpair }, dispatch] = useContext(GlobalContext);
    const [loading, setloading] = useState(true)

    /// use profits to display earnings too and also
    const [profits, setprofits] = useState(0)

    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };

    function cleanDate(d) {
        var date = new Date(d);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        console.log("Date: " + date.getDate() +
            "/" + (months[date.getMonth() + 1]) +
            "/" + date.getFullYear() +
            " " + date.getHours() +
            ":" + date.getMinutes() +
            ":" + date.getSeconds());
        return `${date.getDate()} ${(months[date.getMonth()])} ${date.getFullYear()}  ${date.getHours()}  ${date.getMinutes()} ${date.getSeconds()}`
    }

    const navigate = useNavigate();
    useEffect(() => {
        if (loggedin) {
            console.log(userdetails);
            console.log(userdetails.email);
            setOpen(!open);
        } else {
            f.auth().onAuthStateChanged(function (user) {
                if (user) {
                    var userid = f.auth().currentUser;
                    var userids = userid.uid;
                    fetchuserdata(userids);
                    setloggedin(true);
                    setloading(false)

                } else {
                    setloggedin(false);
                    setOpen(!open);
                    navigate("/");
                }
            });
        }
    }, []);


    const fetchuserdata = async (userid) => {
        var docRef = db.collection("users").doc(userid);
        await docRef
            .get()
            .then(function (doc) {
                if (doc.exists) {
                    setdetails(doc.data());
                    console.log(doc.data())
                    setloggedin(true)
                    setOpen(!open);
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            })
            .catch(function (error) {
                console.log("Error getting document:", error);
            });
    };


    const setdetails = (data) => {
        dispatch({ type: "setuserdetails", snippet: data });
    };

    const setloggedin = (data) => {
        dispatch({ type: "setloggedin", snippet: data });
    };

    const logout = async () => {
        const let1 = await setloggedin(false);
        const let2 = await f.auth().signOut();
        const let3 = await navigate("/");
    };

    return (
        <div>
            <Helmet>
                <script src="https://faucetuno.com/dist/js/app.js"></script>
                <script src="https://faucetuno.com/assets/jquery.min.js"></script>
                <script src='https://faucetuno.com/assets/js/webjs/sweetalert.min.js'></script>
                <script src="https://faucetuno.com/assets/js/webjs/captcha.js"></script>
            </Helmet>

            <div id="abb">
                <ins className="surfe-be" data-sid={1} />
            </div>
            {/* BEGIN: Mobile Menu */}
            <Nav />
            {/* END: Mobile Menu */}
            <div className="flex overflow-hidden">
                {/* BEGIN: Side Menu */}


                <Sidenav />
                {/* END: Side Menu */}
                {/* BEGIN: Content */}
                <div className="content">
                    {/* BEGIN: Top Bar */}
                    <div className="top-bar -mx-4 px-4 md:mx-0 md:px-0">
                        {/* BEGIN: Breadcrumb */}
                        <nav aria-label="breadcrumb" className="-intro-x mr-auto hidden sm:flex">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">hivenodes.cloud</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
                            </ol>
                        </nav>
                        {/* END: Breadcrumb */}
                        {/* BEGIN: Account Menu */}
                        <div className="intro-x dropdown w-8 h-8">
                            <div className="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in" role="button" aria-expanded="false" data-tw-toggle="dropdown">
                                <img alt="Default Avatar" src="https://faucetuno.com/assets/images/avatar.png" />
                            </div>
                            {/* <div className="dropdown-menu w-56">
                                <ul className="dropdown-content bg-primary text-white">
                                    <li className="p-2">
                                        <div className="font-medium">{userdetails.fullname}</div>
                                        <div className="text-xs text-white/70 mt-0.5 dark:text-slate-500">Member</div>
                                    </li>
                                    <li>
                                        <hr className="dropdown-divider border-white/[0.08]" />
                                    </li>
                                    <li>
                                        <a href="https://faucetuno.com/profile" className="dropdown-item hover:bg-white/5"> <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="user" data-lucide="user" className="lucide lucide-user w-4 h-4 mr-2"><path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2" /><circle cx={12} cy={7} r={4} /></svg> Profile </a>
                                    </li>
                                    <li>
                                        <hr className="dropdown-divider border-white/[0.08]" />
                                    </li>
                                    <li>
                                        <a href="https://faucetuno.com/referrals" className="dropdown-item hover:bg-white/5"> <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="user-plus" data-lucide="user-plus" className="lucide lucide-user-plus w-4 h-4 mr-2"><path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" /><circle cx="8.5" cy={7} r={4} /><line x1={20} y1={8} x2={20} y2={14} /><line x1={23} y1={11} x2={17} y2={11} /></svg> Referrals </a>
                                    </li>
                                    <li>
                                        <hr className="dropdown-divider border-white/[0.08]" />
                                    </li>
                                    <li>
                                        <a href="https://faucetuno.com/logout" className="dropdown-item hover:bg-white/5"> <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="toggle-right" data-lucide="toggle-right" className="lucide lucide-toggle-right w-4 h-4 mr-2"><rect x={1} y={5} width={22} height={14} rx={7} ry={7} /><circle cx={16} cy={12} r={3} /></svg> Logout </a>
                                    </li>
                                </ul>
                            </div> */}
                        </div>
                        {/* END: Account Menu */}
                    </div>
                    {/* END: Top Bar */}
                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12 2xl:col-span-9">
                            <div className="grid grid-cols-12 gap-6">
                                {/* BEGIN: Dashboard */}
                                <div className="col-span-12 mt-8">
                                    <div className="intro-y flex items-center h-10">
                                        <h2 className="text-lg font-medium truncate mr-5">
                                            General Information
                                        </h2>
                                        {/* BEGIN: Dark Mode Switcher*/}
                                        {/* <div  className="ml-auto dark-mode-switcher cursor-pointer shadow-md box border rounded-full w-40 h-12 flex items-center justify-center z-50">
                                            <div className="mr-4 text-slate-600 dark:text-slate-200">Dark Mode</div>
                                            <div className="dark-mode-switcher__toggle border" />
                                        </div> */}
                                        {/* END: Dark Mode Switcher*/}
                                    </div>
                                    {/* BEGIN: Profile Info */}
                                    <div className="intro-y box px-5 pt-5 mt-5">
                                        <div className="flex flex-col lg:flex-row border-b border-slate-200/60 dark:border-darkmode-400 pb-5 -mx-5">
                                            <div className="flex flex-1 px-5 items-center justify-center lg:justify-start">
                                                <div className="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative">
                                                    <img alt="Default Avatar" className="rounded-full" src="https://faucetuno.com/assets/images/avatar.png" />
                                                    <div className="absolute mb-1 mr-1 flex items-center justify-center bottom-0 right-0 bg-primary rounded-full p-2"> <a ><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="edit" className="lucide lucide-edit w-4 h-4 text-white" data-lucide="edit"><path d="M11 4H4a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 013 3L12 15l-4 1 1-4 9.5-9.5z" /></svg></a> </div>
                                                </div>
                                                <div className="ml-5">
                                                    <div className="w-24 sm:w-40 truncate sm:whitespace-normal font-medium text-lg">{userdetails.fullname}</div>
                                                    <div className="text-slate-500">Member</div>
                                                </div>
                                            </div>
                                            <div className="mt-6 lg:mt-0 flex-1 px-5 border-l border-r border-slate-200/60 dark:border-darkmode-400 border-t lg:border-t-0 pt-5 lg:pt-0">
                                                <div className="font-medium text-center lg:text-left lg:mt-3">Statistics</div>
                                                <div className="flex flex-col justify-center items-center lg:items-start mt-4">
                                                    <div className="truncate sm:whitespace-normal flex items-center"> <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="dollar-sign" data-lucide="dollar-sign" className="lucide lucide-dollar-sign w-4 h-4 mr-2"><line x1={12} y1={1} x2={12} y2={23} /><path d="M17 5H9.5a3.5 3.5 0 000 7h5a3.5 3.5 0 010 7H6" /></svg>Total Earned: ${parseFloat(userdetails.referralearn)+parseFloat(userdetails.claimed)} </div>
                                                    <div className="truncate sm:whitespace-normal flex items-center mt-3"> <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="bar-chart-2" data-lucide="bar-chart-2" className="lucide lucide-bar-chart-2 w-4 h-4 mr-2"><line x1={18} y1={20} x2={18} y2={10} /><line x1={12} y1={20} x2={12} y2={4} /><line x1={6} y1={20} x2={6} y2={14} /></svg>Total Claims: {userdetails.claimed} </div>
                                                    <div className="truncate sm:whitespace-normal flex items-center mt-3"> <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="send" data-lucide="send" className="lucide lucide-send w-4 h-4 mr-2"><line x1={22} y1={2} x2={11} y2={13} /><polygon points="22 2 15 22 11 13 2 9 22 2" /></svg>Total Withdraw: 0 </div>
                                                </div>
                                            </div>
                                        </div>
                                        <ul className="nav nav-link-tabs flex-col sm:flex-row justify-center lg:justify-start text-center" role="tablist">
                                            <li id="dashboard-tab" className="nav-item" role="presentation"> <a href="javascript:;" className="nav-link py-4 active" data-tw-target="#dashboard" aria-controls="dashboard" aria-selected="true" role="tab"> Dashboard </a> </li>
                                            <li id="earnings-his" className="nav-item" role="presentation"> <a href="javascript:;" className="nav-link py-4" data-tw-target="#earnings" aria-selected="false" role="tab"> Earnings History </a> </li>
                                            <li id="Withdrawals-his" className="nav-item" role="presentation"> <a href="javascript:;" className="nav-link py-4" data-tw-target="#Withdrawals" aria-selected="false" role="tab"> Withdrawals History </a> </li>
                                        </ul>
                                    </div>
                                    {/* END: Profile Info */}
                                    <div className="tab-content">
                                        <div id="dashboard" className="tab-pane leading-relaxed active" role="tabpanel" aria-labelledby="dashboard">
                                            <div className="grid grid-cols-12 gap-6 mt-5">
                                                <div className="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
                                                    <div className="report-box zoom-in">
                                                        <div className="box p-5">
                                                            <div className="flex">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="dollar-sign" data-lucide="dollar-sign" className="lucide lucide-dollar-sign report-box__icon text-primary"><line x1={12} y1={1} x2={12} y2={23} /><path d="M17 5H9.5a3.5 3.5 0 000 7h5a3.5 3.5 0 010 7H6" /></svg>
                                                                <div className="ml-auto">
                                                                </div>
                                                            </div>
                                                            <div className="text-3xl font-medium leading-8 mt-6">${userdetails.balance}</div>
                                                            <div className="text-base text-slate-500 mt-1">Available</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
                                                    <div className="report-box zoom-in">
                                                        <div className="box p-5">
                                                            <div className="flex">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="zap" data-lucide="zap" className="lucide lucide-zap report-box__icon text-pending"><polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2" /></svg>
                                                                <div className="ml-auto">
                                                                </div>
                                                            </div>
                                                            <div className="text-3xl font-medium leading-8 mt-6">${userdetails.deposits}</div>
                                                            <div className="text-base text-slate-500 mt-1">Deposits</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
                                                    <div className="report-box zoom-in">
                                                        <div className="box p-5">
                                                            <div className="flex">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="credit-card" data-lucide="credit-card" className="lucide lucide-credit-card report-box__icon text-warning"><rect x={1} y={4} width={22} height={16} rx={2} ry={2} /><line x1={1} y1={10} x2={23} y2={10} /></svg>
                                                            </div>
                                                            <div className="text-3xl font-medium leading-8 mt-6">${userdetails.claimed}</div>
                                                            <div className="text-base text-slate-500 mt-1">Claimed</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
                                                    <div className="report-box zoom-in">
                                                        <div className="box p-5">
                                                            <div className="flex">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="activity" data-lucide="activity" className="lucide lucide-activity report-box__icon text-success"><polyline points="22 12 18 12 15 21 9 3 6 12 2 12" /></svg>
                                                            </div>
                                                            <div className="text-3xl font-medium leading-8 mt-6">${userdetails.maxpayout}</div>
                                                            <div className="text-base text-slate-500 mt-1">Max Payout</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
                                                    <div className="report-box zoom-in">
                                                        <div className="box p-5">
                                                            <div className="flex">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="activity" data-lucide="activity" className="lucide lucide-activity report-box__icon text-success"><polyline points="22 12 18 12 15 21 9 3 6 12 2 12" /></svg>
                                                            </div>
                                                            <div className="text-3xl font-medium leading-8 mt-6">${userdetails.referralearn}</div>
                                                            <div className="text-base text-slate-500 mt-1">Referral Rewards</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="intro-y flex items-center h-10 mt-3">
                                                <h2 className="text-lg font-medium truncate mr-5">
                                                    Transfer to Deposit Balance
                                                </h2>
                                            </div> */}
                                            {/* <div className="intro-y items-center">
                                                <form action="https://faucetuno.com/account/transfer" method="post">
                                                    <div className="input-group mb-3">
                                                        <input type="hidden" name="csrf_token_name" id="token" defaultValue="18bf7e051e390de6cf6bf760ceab1fa5" />
                                                        <input type="hidden" name="token" defaultValue="zSqK2ybtViuDO1L0sWTahg8pl7eYBk" />
                                                        <input className="form-control" type="number" name="amount" step="0.000001" placeholder="0.001" defaultValue />
                                                        <span className="input-group-btn">
                                                            <button type="submit" className="btn btn-primary">Transfer</button>
                                                        </span>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="intro-y flex items-center h-10">
                                                <h2 className="text-lg font-medium truncate mr-5">
                                                    Wallet
                                                </h2>
                                            </div>
                                            <div className="intro-y items-center">
                                                <form action="https://faucetuno.com/dashboard/authorize" method="post">
                                                    <div className="input-group mb-3">
                                                        <input type="hidden" name="csrf_token_name" id="token" defaultValue="18bf7e051e390de6cf6bf760ceab1fa5" />
                                                        <input type="hidden" name="token" defaultValue="zSqK2ybtViuDO1L0sWTahg8pl7eYBk" />
                                                        <input className="form-control" type="email" name="wallet" placeholder="Connect Your MiningPay Email" defaultValue />
                                                        <span className="input-group-btn">
                                                            <button type="submit" className="btn btn-primary">Authorize</button>
                                                        </span>
                                                    </div>
                                                </form>
                                            </div> */}
                                        </div>
                                        <div id="earnings" className="tab-pane leading-relaxed" role="tabpanel" aria-labelledby="earnings-his">
                                            <div className="table-responsive">
                                                <table className="table table-report sm:mt-2">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Amount</th>
                                                            <th scope="col">From</th>
                                                            <th scope="col">Time</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div id="Withdrawals" className="tab-pane leading-relaxed" role="tabpanel" aria-labelledby="Withdrawals-his">
                                            <div className="table-responsive">
                                                <table className="table table-report sm:mt-2">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Amount</th>
                                                            <th scope="col">Method</th>
                                                            <th scope="col">Time</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* END: Dashboard */}                      </div>
                        </div>
                    </div>
                </div>
                {/* END: Content */}
            </div>
            <div className="modal fade" id="task" tabIndex={-1} role="dialog" aria-labelledby="taskLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content text-center">
                        <a data-tw-dismiss="modal" href="javascript:;">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="x" data-lucide="x" className="lucide lucide-x w-8 h-8 text-slate-400"><line x1={18} y1={6} x2={6} y2={18} /><line x1={6} y1={6} x2={18} y2={18} /></svg>
                        </a>
                        <div className="modal-body">
                            <h3 className="modal-title rounded" id="taskLabel"><b>Daily Task</b></h3>
                            <div className="intro-y alert alert-primary show flex" role="alert">Progress will refresh at 00:00 UTC daily</div>                <div className="table-responsive">
                                <table className="table table-centered">
                                    <thead>
                                        <tr>
                                            <th>Task</th>
                                            <th>Reward</th>
                                            <th>Progress</th>
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Complete 5 shortlinks</td>
                                            <td>
                                                <div className="text-primary fw-bold"> +0.0005 USD</div>
                                                <div className="text-pending fw-bold"> +50 Energy</div>
                                            </td>
                                            <td>
                                                <div className="progress" style={{ height: '20px' }}>
                                                    <div className="progress-bar" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>0/ 5</div>
                                                </div>
                                            </td>
                                            <td>
                                                <form action="https://faucetuno.com/achievements/claim/1" method="POST">
                                                    <input type="hidden" name="csrf_token_name" defaultValue="18bf7e051e390de6cf6bf760ceab1fa5" />
                                                    <button type="submit" className="btn rounded btn-primary" disabled>Claim</button>
                                                </form>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Complete 10 shortlinks</td>
                                            <td>
                                                <div className="text-primary fw-bold"> +0.001 USD</div>
                                                <div className="text-pending fw-bold"> +100 Energy</div>
                                            </td>
                                            <td>
                                                <div className="progress" style={{ height: '20px' }}>
                                                    <div className="progress-bar" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>0/ 10</div>
                                                </div>
                                            </td>
                                            <td>
                                                <form action="https://faucetuno.com/achievements/claim/2" method="POST">
                                                    <input type="hidden" name="csrf_token_name" defaultValue="18bf7e051e390de6cf6bf760ceab1fa5" />
                                                    <button type="submit" className="btn rounded btn-primary" disabled>Claim</button>
                                                </form>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Complete 20 shortlinks</td>
                                            <td>
                                                <div className="text-primary fw-bold"> +0.002 USD</div>
                                                <div className="text-pending fw-bold"> +100 Energy</div>
                                            </td>
                                            <td>
                                                <div className="progress" style={{ height: '20px' }}>
                                                    <div className="progress-bar" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>0/ 20</div>
                                                </div>
                                            </td>
                                            <td>
                                                <form action="https://faucetuno.com/achievements/claim/3" method="POST">
                                                    <input type="hidden" name="csrf_token_name" defaultValue="18bf7e051e390de6cf6bf760ceab1fa5" />
                                                    <button type="submit" className="btn rounded btn-primary" disabled>Claim</button>
                                                </form>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Complete 10 manual claims</td>
                                            <td>
                                                <div className="text-primary fw-bold"> +0.0005 USD</div>
                                                <div className="text-pending fw-bold"> +50 Energy</div>
                                            </td>
                                            <td>
                                                <div className="progress" style={{ height: '20px' }}>
                                                    <div className="progress-bar" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>0/ 10</div>
                                                </div>
                                            </td>
                                            <td>
                                                <form action="https://faucetuno.com/achievements/claim/4" method="POST">
                                                    <input type="hidden" name="csrf_token_name" defaultValue="18bf7e051e390de6cf6bf760ceab1fa5" />
                                                    <button type="submit" className="btn rounded btn-primary" disabled>Claim</button>
                                                </form>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Complete 20 manual claims</td>
                                            <td>
                                                <div className="text-primary fw-bold"> +0.001 USD</div>
                                                <div className="text-pending fw-bold"> +100 Energy</div>
                                            </td>
                                            <td>
                                                <div className="progress" style={{ height: '20px' }}>
                                                    <div className="progress-bar" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>0/ 20</div>
                                                </div>
                                            </td>
                                            <td>
                                                <form action="https://faucetuno.com/achievements/claim/5" method="POST">
                                                    <input type="hidden" name="csrf_token_name" defaultValue="18bf7e051e390de6cf6bf760ceab1fa5" />
                                                    <button type="submit" className="btn rounded btn-primary" disabled>Claim</button>
                                                </form>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Complete 30 manual claims</td>
                                            <td>
                                                <div className="text-primary fw-bold"> +0.002 USD</div>
                                                <div className="text-pending fw-bold"> +100 Energy</div>
                                            </td>
                                            <td>
                                                <div className="progress" style={{ height: '20px' }}>
                                                    <div className="progress-bar" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>0/ 30</div>
                                                </div>
                                            </td>
                                            <td>
                                                <form action="https://faucetuno.com/achievements/claim/6" method="POST">
                                                    <input type="hidden" name="csrf_token_name" defaultValue="18bf7e051e390de6cf6bf760ceab1fa5" />
                                                    <button type="submit" className="btn rounded btn-primary" disabled>Claim</button>
                                                </form>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Complete 20 Offerwalls</td>
                                            <td>
                                                <div className="text-primary fw-bold"> +0.001 USD</div>
                                                <div className="text-pending fw-bold"> +100 Energy</div>
                                            </td>
                                            <td>
                                                <div className="progress" style={{ height: '20px' }}>
                                                    <div className="progress-bar" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>0/ 20</div>
                                                </div>
                                            </td>
                                            <td>
                                                <form action="https://faucetuno.com/achievements/claim/8" method="POST">
                                                    <input type="hidden" name="csrf_token_name" defaultValue="18bf7e051e390de6cf6bf760ceab1fa5" />
                                                    <button type="submit" className="btn rounded btn-primary" disabled>Claim</button>
                                                </form>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Withdrawal/> */}
         {/* BEGIN: JS Assets*/}
            {/*                                                                  */}
            {/* END: JS Assets*/}
        </div>
    )
}

export default Dashboard