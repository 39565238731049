import React from 'react'
import { Helmet } from 'react-helmet'
import Nav from '../components/Nav/Nav'
import Sidenav from '../components/Nav/Sidenav'
import Withdrawal from '../components/Withdrawal'

function Deposit() {
    return (
        <div>
            <Helmet>
                <script src="https://faucetuno.com/dist/js/app.js"></script>
                <script src="https://faucetuno.com/assets/jquery.min.js"></script>
                <script src='https://faucetuno.com/assets/js/webjs/sweetalert.min.js'></script>
                <script src="https://faucetuno.com/assets/js/webjs/captcha.js"></script>
            </Helmet>

            <div id="abb">
                <ins className="surfe-be" data-sid={1} />
            </div>
            {/* BEGIN: Mobile Menu */}
            <Nav />   {/* END: Mobile Menu */}
            <div className="flex overflow-hidden">
                {/* BEGIN: Side Menu */}
                <Sidenav />           {/* END: Side Menu */}
                {/* BEGIN: Content */}
                <div className="content">
                    {/* BEGIN: Top Bar */}
                    <div className="top-bar -mx-4 px-4 md:mx-0 md:px-0">
                        {/* BEGIN: Breadcrumb */}
                        <nav aria-label="breadcrumb" className="-intro-x mr-auto hidden sm:flex">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">hivenodes.cloud</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Deposit</li>
                            </ol>
                        </nav>
                        {/* END: Breadcrumb */}
                        {/* BEGIN: Account Menu */}
                        <div className="intro-x dropdown w-8 h-8">
                            <div className="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in" role="button" aria-expanded="false" data-tw-toggle="dropdown">
                                <img alt="Default Avatar" src="https://faucetuno.com/assets/images/avatar.png" />
                            </div>
                            {/* <div className="dropdown-menu w-56">
                                <ul className="dropdown-content bg-primary text-white">
                                    <li className="p-2">
                                        <div className="font-medium">richardallan460</div>
                                        <div className="text-xs text-white/70 mt-0.5 dark:text-slate-500">Member</div>
                                    </li>
                                    <li>
                                        <hr className="dropdown-divider border-white/[0.08]" />
                                    </li>
                                    <li>
                                        <a href="https://faucetuno.com/profile" className="dropdown-item hover:bg-white/5"> <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="user" data-lucide="user" className="lucide lucide-user w-4 h-4 mr-2"><path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2" /><circle cx={12} cy={7} r={4} /></svg> Profile </a>
                                    </li>
                                    <li>
                                        <hr className="dropdown-divider border-white/[0.08]" />
                                    </li>
                                    <li>
                                        <a href="https://faucetuno.com/referrals" className="dropdown-item hover:bg-white/5"> <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="user-plus" data-lucide="user-plus" className="lucide lucide-user-plus w-4 h-4 mr-2"><path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" /><circle cx="8.5" cy={7} r={4} /><line x1={20} y1={8} x2={20} y2={14} /><line x1={23} y1={11} x2={17} y2={11} /></svg> Referrals </a>
                                    </li>
                                    <li>
                                        <hr className="dropdown-divider border-white/[0.08]" />
                                    </li>
                                    <li>
                                        <a href="https://faucetuno.com/logout" className="dropdown-item hover:bg-white/5"> <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="toggle-right" data-lucide="toggle-right" className="lucide lucide-toggle-right w-4 h-4 mr-2"><rect x={1} y={5} width={22} height={14} rx={7} ry={7} /><circle cx={16} cy={12} r={3} /></svg> Logout </a>
                                    </li>
                                </ul>
                            </div> */}
                        </div>
                        {/* END: Account Menu */}
                    </div>
                    {/* END: Top Bar */}
                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12 2xl:col-span-9">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12 mt-8">
                                    <div className="intro-y flex items-center h-10">
                                        <h2 className="text-lg font-medium truncate mr-5">
                                            Deposit                                  </h2>
                                        <a href className="ml-auto flex items-center text-primary"> <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="refresh-ccw" data-lucide="refresh-ccw" className="lucide lucide-refresh-ccw w-4 h-4 mr-3"><path d="M3 2v6h6" /><path d="M21 12A9 9 0 006 5.3L3 8" /><path d="M21 22v-6h-6" /><path d="M3 12a9 9 0 0015 6.7l3-2.7" /></svg> Reload Data </a>
                                    </div>
                                    <div className="grid grid-cols-12 gap-6 mt-5">
                                        <div className="col-span-12 sm:col-span-6 xl:col-span-6 intro-y">
                                            <div className="report-box zoom-in">
                                                <div className="box p-5">
                                                    <div className="flex">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="dollar-sign" data-lucide="dollar-sign" className="lucide lucide-dollar-sign report-box__icon text-primary"><line x1={12} y1={1} x2={12} y2={23} /><path d="M17 5H9.5a3.5 3.5 0 000 7h5a3.5 3.5 0 010 7H6" /></svg>
                                                    </div>
                                                    <div className="text-3xl font-medium leading-8 mt-6">$0</div>
                                                    <div className="text-base text-slate-500 mt-1">Account Balance</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-12 sm:col-span-6 xl:col-span-6 intro-y">
                                            <div className="report-box zoom-in">
                                                <div className="box p-5">
                                                    <div className="flex">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="credit-card" data-lucide="credit-card" className="lucide lucide-credit-card report-box__icon text-warning"><rect x={1} y={4} width={22} height={16} rx={2} ry={2} /><line x1={1} y1={10} x2={23} y2={10} /></svg>
                                                    </div>
                                                    <div className="text-3xl font-medium leading-8 mt-6">$0</div>
                                                    <div className="text-base text-slate-500 mt-1">Deposit Balance</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-12 intro-y">
                                            <div className="mt-3 mb-2">
                                            </div>
                                            <ul className="nav nav-tabs" role="tablist">
                                                <li id="fp-depo" className="nav-item flex-1" role="presentation">
                                                    <button className="nav-link w-full py-2" data-tw-toggle="pill" data-tw-target="#fp-dep" type="button" role="tab" aria-controls="fp-dep" aria-selected="true">
                                                        MiningPay Deposit
                                                    </button>
                                                </li>
                                                <li id="py-depo" className="nav-item flex-1" role="presentation">
                                                    <button className="nav-link w-full py-2" data-tw-toggle="pill" data-tw-target="#py-dep" type="button" role="tab" aria-controls="py-dep" aria-selected="false">
                                                        Payeer Deposit
                                                    </button>
                                                </li>
                                            </ul>
                                            <div className="tab-content border-l border-r border-b">
                                                <div id="fp-dep" className="tab-pane leading-relaxed p-5" role="tabpanel" aria-labelledby="fp-depo">
                                                    <div className="box p-5">
                                                        <form  >
                                                            <div className="form-group">
                                                                <label>Amount (USD) :</label>
                                                                <input type="number" name="amount1" className="form-control" min={1} step="0.000001" />
                                                            </div>


                                                            <a href="javascript:;" data-tw-toggle="modal" data-tw-target="#withdraw" className="side-menu">
                                                                <div className="btn btn-success">Deposit</div>
                                                            </a>

                                                            <small>You can pay with other currencies after creating the deposit.</small>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div id="py-dep" className="tab-pane leading-relaxed p-5" role="tabpanel" aria-labelledby="py-depo">
                                                    <div className="box p-5">
                                                        <form autoComplete="off">
                                                            <div className="form-group">
                                                                <label>Amount (USD) :</label>
                                                                <input type="number" name="amount" className="form-control" min={1} step="0.001" />
                                                            </div>
                                                            <input type="hidden" name="csrf_token_name" defaultValue="18bf7e051e390de6cf6bf760ceab1fa5" />

                                                            <a href="javascript:;" data-tw-toggle="modal" data-tw-target="#withdraw" className="side-menu">
                                                                <div className="btn btn-success">Deposit</div>
                                                            </a>

                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-12 mt-6">
                                            <div className="intro-y block sm:flex items-center h-10">
                                                <h2 className="text-lg font-medium truncate mr-5">
                                                    Deposit History
                                                </h2>
                                            </div>
                                            <div className="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
                                                <div className="table-responsive">
                                                    <table className="table table-report sm:mt-2">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Code</th>
                                                                <th scope="col">Status</th>
                                                                <th scope="col">Amount</th>
                                                                <th scope="col">Time</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                      </div>
                            </div>
                        </div>
                    </div>
                    {/* END: Content */}
                </div>
                <div className="modal fade" id="task" tabIndex={-1} role="dialog" aria-labelledby="taskLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content text-center">
                            <a data-tw-dismiss="modal" href="javascript:;">
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="x" data-lucide="x" className="lucide lucide-x w-8 h-8 text-slate-400"><line x1={18} y1={6} x2={6} y2={18} /><line x1={6} y1={6} x2={18} y2={18} /></svg>
                            </a>
                            <div className="modal-body">
                                <h3 className="modal-title rounded" id="taskLabel"><b>Daily Task</b></h3>
                                <div className="intro-y alert alert-primary show flex" role="alert">Progress will refresh at 00:00 UTC daily</div>                <div className="table-responsive">
                                    <table className="table table-centered">
                                        <thead>
                                            <tr>
                                                <th>Task</th>
                                                <th>Reward</th>
                                                <th>Progress</th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Complete 5 shortlinks</td>
                                                <td>
                                                    <div className="text-primary fw-bold"> +0.0005 USD</div>
                                                    <div className="text-pending fw-bold"> +50 Energy</div>
                                                </td>
                                                <td>
                                                    <div className="progress" style={{ height: '20px' }}>
                                                        <div className="progress-bar" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>0/ 5</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <form action="https://faucetuno.com/achievements/claim/1" method="POST">
                                                        <input type="hidden" name="csrf_token_name" defaultValue="18bf7e051e390de6cf6bf760ceab1fa5" />
                                                        <button type="submit" className="btn rounded btn-primary" disabled>Claim</button>
                                                    </form>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Complete 10 shortlinks</td>
                                                <td>
                                                    <div className="text-primary fw-bold"> +0.001 USD</div>
                                                    <div className="text-pending fw-bold"> +100 Energy</div>
                                                </td>
                                                <td>
                                                    <div className="progress" style={{ height: '20px' }}>
                                                        <div className="progress-bar" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>0/ 10</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <form action="https://faucetuno.com/achievements/claim/2" method="POST">
                                                        <input type="hidden" name="csrf_token_name" defaultValue="18bf7e051e390de6cf6bf760ceab1fa5" />
                                                        <button type="submit" className="btn rounded btn-primary" disabled>Claim</button>
                                                    </form>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Complete 20 shortlinks</td>
                                                <td>
                                                    <div className="text-primary fw-bold"> +0.002 USD</div>
                                                    <div className="text-pending fw-bold"> +100 Energy</div>
                                                </td>
                                                <td>
                                                    <div className="progress" style={{ height: '20px' }}>
                                                        <div className="progress-bar" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>0/ 20</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <form action="https://faucetuno.com/achievements/claim/3" method="POST">
                                                        <input type="hidden" name="csrf_token_name" defaultValue="18bf7e051e390de6cf6bf760ceab1fa5" />
                                                        <button type="submit" className="btn rounded btn-primary" disabled>Claim</button>
                                                    </form>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Complete 10 manual claims</td>
                                                <td>
                                                    <div className="text-primary fw-bold"> +0.0005 USD</div>
                                                    <div className="text-pending fw-bold"> +50 Energy</div>
                                                </td>
                                                <td>
                                                    <div className="progress" style={{ height: '20px' }}>
                                                        <div className="progress-bar" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>0/ 10</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <form action="https://faucetuno.com/achievements/claim/4" method="POST">
                                                        <input type="hidden" name="csrf_token_name" defaultValue="18bf7e051e390de6cf6bf760ceab1fa5" />
                                                        <button type="submit" className="btn rounded btn-primary" disabled>Claim</button>
                                                    </form>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Complete 20 manual claims</td>
                                                <td>
                                                    <div className="text-primary fw-bold"> +0.001 USD</div>
                                                    <div className="text-pending fw-bold"> +100 Energy</div>
                                                </td>
                                                <td>
                                                    <div className="progress" style={{ height: '20px' }}>
                                                        <div className="progress-bar" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>0/ 20</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <form action="https://faucetuno.com/achievements/claim/5" method="POST">
                                                        <input type="hidden" name="csrf_token_name" defaultValue="18bf7e051e390de6cf6bf760ceab1fa5" />
                                                        <button type="submit" className="btn rounded btn-primary" disabled>Claim</button>
                                                    </form>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Complete 30 manual claims</td>
                                                <td>
                                                    <div className="text-primary fw-bold"> +0.002 USD</div>
                                                    <div className="text-pending fw-bold"> +100 Energy</div>
                                                </td>
                                                <td>
                                                    <div className="progress" style={{ height: '20px' }}>
                                                        <div className="progress-bar" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>0/ 30</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <form action="https://faucetuno.com/achievements/claim/6" method="POST">
                                                        <input type="hidden" name="csrf_token_name" defaultValue="18bf7e051e390de6cf6bf760ceab1fa5" />
                                                        <button type="submit" className="btn rounded btn-primary" disabled>Claim</button>
                                                    </form>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Complete 20 Offerwalls</td>
                                                <td>
                                                    <div className="text-primary fw-bold"> +0.001 USD</div>
                                                    <div className="text-pending fw-bold"> +100 Energy</div>
                                                </td>
                                                <td>
                                                    <div className="progress" style={{ height: '20px' }}>
                                                        <div className="progress-bar" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>0/ 20</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <form action="https://faucetuno.com/achievements/claim/8" method="POST">
                                                        <input type="hidden" name="csrf_token_name" defaultValue="18bf7e051e390de6cf6bf760ceab1fa5" />
                                                        <button type="submit" className="btn rounded btn-primary" disabled>Claim</button>
                                                    </form>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Withdrawal />      */}





                <div className="modal fade" id="withdraw" tabIndex={-1} role="dialog" aria-labelledby="withdrawLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content text-center">
                            <a data-tw-dismiss="modal" href="javascript:;">
                                <i data-lucide="x" className="w-8 h-8 text-slate-400" />
                            </a>
                            <div className="modal-body">
                                <h3 className="modal-title rounded" id="withdrawLabel"><b>Deposit Account Balance</b></h3>
                                <div className="box p-5 mt-3">
                                    <p><b>BTC Deposit Wallet Address</b></p>
                                    <form >

                                        <div className="form-group">
                                            <input type="number" className="form-control" name="amount" min="0.01" step="0.000001" defaultValue={0} required />
                                        </div>
                                        {/* <button type="submit" className="btn btn-primary">Withdraw</button> */}
                                    </form>
                                    <p><b> 1LB8i5WXsmQu9Ld8EYoHsvCgYSZTXuT5jP</b></p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* BEGIN: JS Assets*/}
                {/* END: JS Assets*/}
            </div>
        </div>
    )
}

export default Deposit