import React from 'react'
import { Helmet } from 'react-helmet'
import Nav from '../components/Nav/Nav'
import Sidenav from '../components/Nav/Sidenav'
import Withdrawal from '../components/Withdrawal'

function Claim() {
    return (
        <div>
            <Helmet>
                <script src="https://faucetuno.com/dist/js/app.js"></script>
                <script src="https://faucetuno.com/assets/jquery.min.js"></script>
                <script src='https://faucetuno.com/assets/js/webjs/sweetalert.min.js'></script>
                {/* <script src="https://faucetuno.com/assets/js/webjs/captcha.js"></script> */}
            </Helmet>

            {/* END: Head */}
            <div id="abb">
                <ins className="surfe-be" data-sid={1} />
            </div>
            {/* BEGIN: Mobile Menu */}
            <Nav />      {/* END: Mobile Menu */}
            <div className="flex overflow-hidden">
                {/* BEGIN: Side Menu */}
                <Sidenav />           {/* END: Side Menu */}
                {/* BEGIN: Content */}
                <div className="content">
                    {/* BEGIN: Top Bar */}
                    <div className="top-bar -mx-4 px-4 md:mx-0 md:px-0">
                        {/* BEGIN: Breadcrumb */}
                        <nav aria-label="breadcrumb" className="-intro-x mr-auto hidden sm:flex">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">hivenodes.cloud</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Manual Claim</li>
                            </ol>
                        </nav>
                        {/* END: Breadcrumb */}
                        {/* BEGIN: Account Menu */}
                        <div className="intro-x dropdown w-8 h-8">
                            <div className="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in" role="button" aria-expanded="false" data-tw-toggle="dropdown">
                                <img alt="Default Avatar" src="https://faucetuno.com/assets/images/avatar.png" />
                            </div>
                            {/* <div className="dropdown-menu w-56">
                                <ul className="dropdown-content bg-primary text-white">
                                    <li className="p-2">
                                        <div className="font-medium">richardallan460</div>
                                        <div className="text-xs text-white/70 mt-0.5 dark:text-slate-500">Member</div>
                                    </li>
                                    <li>
                                        <hr className="dropdown-divider border-white/[0.08]" />
                                    </li>
                                    <li>
                                        <a href="https://faucetuno.com/profile" className="dropdown-item hover:bg-white/5"> <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="user" data-lucide="user" className="lucide lucide-user w-4 h-4 mr-2"><path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2" /><circle cx={12} cy={7} r={4} /></svg> Profile </a>
                                    </li>
                                    <li>
                                        <hr className="dropdown-divider border-white/[0.08]" />
                                    </li>
                                    <li>
                                        <a href="https://faucetuno.com/referrals" className="dropdown-item hover:bg-white/5"> <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="user-plus" data-lucide="user-plus" className="lucide lucide-user-plus w-4 h-4 mr-2"><path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" /><circle cx="8.5" cy={7} r={4} /><line x1={20} y1={8} x2={20} y2={14} /><line x1={23} y1={11} x2={17} y2={11} /></svg> Referrals </a>
                                    </li>
                                    <li>
                                        <hr className="dropdown-divider border-white/[0.08]" />
                                    </li>
                                    <li>
                                        <a href="https://faucetuno.com/logout" className="dropdown-item hover:bg-white/5"> <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="toggle-right" data-lucide="toggle-right" className="lucide lucide-toggle-right w-4 h-4 mr-2"><rect x={1} y={5} width={22} height={14} rx={7} ry={7} /><circle cx={16} cy={12} r={3} /></svg> Logout </a>
                                    </li>
                                </ul>
                            </div> */}
                        </div>
                        {/* END: Account Menu */}
                    </div>
                    {/* END: Top Bar */}
                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12 2xl:col-span-9">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12 mt-8">
                                    <div className="intro-y flex items-center h-10">
                                        <h2 className="text-lg font-medium truncate mr-5">
                                            Manual Claim                                  </h2>
                                    </div>
                                    <div className="grid grid-cols-12 gap-6 mt-5">
                                        <div className="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
                                            <div className="report-box zoom-in">
                                                <div className="box p-5">
                                                    <div className="flex">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="dollar-sign" data-lucide="dollar-sign" className="lucide lucide-dollar-sign report-box__icon text-primary"><line x1={12} y1={1} x2={12} y2={23} /><path d="M17 5H9.5a3.5 3.5 0 000 7h5a3.5 3.5 0 010 7H6" /></svg>
                                                    </div>
                                                    <div className="text-3xl font-medium leading-8 mt-6">$0.0002</div>
                                                    <div className="text-base text-slate-500 mt-1">USD Reward</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
                                            <div className="report-box zoom-in">
                                                <div className="box p-5">
                                                    <div className="flex">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="zap" data-lucide="zap" className="lucide lucide-zap report-box__icon text-pending"><polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2" /></svg>
                                                    </div>
                                                    <div className="text-3xl font-medium leading-8 mt-6">10</div>
                                                    <div className="text-base text-slate-500 mt-1">Energy Reward</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
                                            <div className="report-box zoom-in">
                                                <div className="box p-5">
                                                    <div className="flex">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="timer" data-lucide="timer" className="lucide lucide-timer report-box__icon text-warning"><line x1={10} x2={14} y1={2} y2={2} /><line x1={12} x2={15} y1={14} y2={11} /><circle cx={12} cy={14} r={8} /></svg>
                                                    </div>
                                                    <div className="text-3xl font-medium leading-8 mt-6">
                                                        10 Minutes
                                                    </div>
                                                    <div className="text-base text-slate-500 mt-1">Mining Timer</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
                                            <div className="report-box zoom-in">
                                                <div className="box p-5">
                                                    <div className="flex">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="activity" data-lucide="activity" className="lucide lucide-activity report-box__icon text-success"><polyline points="22 12 18 12 15 21 9 3 6 12 2 12" /></svg>
                                                    </div>
                                                    <div className="text-3xl font-medium leading-8 mt-6">100/100</div>
                                                    <div className="text-base text-slate-500 mt-1">Claims Left</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <center className="m-5">
                                        {/* Adcryp.to - Ad Display Code */}
                                        <div id="adm-container-3834" />
                                        {/* Adcryp.to - Ad Display Code */}
                                        <br />
                                        <style dangerouslySetInnerHTML={{ __html: "\n.al {\nposition: absolute;\nright: 0px;\nbottom: 0px;\nwidth: 69px;\nheight: 13px;\nbackground: url(https://p3.adhitzads.com/s/bannerslink.png);\ncursor: pointer;\n}\n.rt {\ndisplay: none;\nfont-weight: bold;\nposition: absolute;\nright: 0px;\nfont-size: 11px;\nbottom: 13px;\ntext-decoration: none;\nfont-family: tahoma,arial;\nbackground-color: #E2EFC0;\nborder: 2px solid #BCD971;\ncolor: #799915;\npadding: 4px 6px;\nwhite-space: nowrap;\n}\n" }} /><div><div>
                                         
                                        </div>
                                            {/* <div className="becon">
                                                <img src="https://api.adhitz.com/www/delivery/lg.php?bannerid=5377&campaignid=5377&zoneid=33140&l=file%3A%2F%2F%2FC%3A%2FUsers%2FUNIK%2FDesktop%2FFaucet%2Fpublic%2Fclaim.html&p=1381536787&b=MC4wMTA=&r=NzA=&pos=All Page&a=&t=0&cb={random}" width="1px" height="1px" />
                                            </div> */}
                                        </div>
                                    </center>
                                    <div className="grid grid-cols-12 gap-2 mt-6">
                                        <div className="col-span-12 sm:col-span-6 xl:col-span-8 text-center">
                                            <form id="fauform" className="rounded" >
                                                <input type="hidden" name="antibotlinks" id="antibotlinks" defaultValue /><div className="alert alert-warning show flex text-center" role="alert">Please click on the Anti-Bot links in the following order <span className="inline"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHAAAAAYCAYAAAAiR3l8AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC6UlEQVRoge2ZvWsUQRiHn4iEC8xItBIUCZLCwkqIBL+wEGFIEUREMPgPxEKwkRDERhCLFIIg2mgnFiIBw3QqBMEihZUInhAkWAdnJFeIWOxs3Jvs7e3H3OXuvAeOuZ17931/v3ln9zY52EWUFFu7Wb+b9J3XdoL7zlAF+nYtelZYSTrhZ9DWqGcZuIUeOEM9RMfX9n9sXrc999Qa95KYMlpC6w+Zb8RLPA08AyYS03Xgujb2U4GYc8AL4EAipgbc0cbeCyW+gu4a0EjGKCm2tLFjgWr5/mvAJrAELMZ18uRqxx7v+IGXDGDSzReJmaO5eRAt2GyR3aekWFFSbLV4Paygu+HHaGPHcmor478BjAOz3ibJkysTv4FH3BgXuurNN8W4MS2m5sZFbexY4jVVYKHaYcvqbhWT5wrMWSvN/4g2dsrznidXJn4DC+GKvsoIOZRxXhFuunPuu+M6zbt0omC+brHDf8ANDFRsoONy/CYhbN2N80qKD0qKi2USa2Nn3O59qqS4BiwQfZdc0sb+TNRcz0izG6y7MdV/iQ3ckhANjDkWC9PG3gUUsAacAJaVFO+UFCfLJFZSnAEeA7+BOW1sPf4s5GKEIrT/JP7VG7KBX5IH2tj32tizwBXgM3AeeF00qZJiEngJjAIL2ti31aV2nhT/06T7r6XMZeVtugWHbGCrgm+A4/x7EsuNkmIcWCZ6onuujX2kpLjlnkKfhFcbnoR/SPffAFYK5ty+6+wtL601SoqvwGHgrDZ2DTgNrAJ/CuSA6AHpKPARuBFeaWdI+ify3c7/TNlafgO/AweJ7tn+fJGYTSIDq17MBoCSYgmYd3P7tbENdjIOnHLvp4FfXq6gunNqyltr2787jseNErky8W+ht1NOrrv5qjE/yHEVlXzEDqW7k7XS/IfSVI4qf88oKfYpKb4pKWqJuZb5uvH/1DRNA0/FJl5QUox2skZR8moaMmTIkCE9RS/9blqKvjdQgbLe/wIwxWyhrmTAlgAAAABJRU5ErkJggg==" alt="" width={112} height={24} /></span> <a href="#" id="antibotlinks_reset" style={{ display: 'none' }}>( reset )</a></div>                                            <input type="hidden" name="csrf_token_name" id="token" defaultValue="18bf7e051e390de6cf6bf760ceab1fa5" />
                                                <input type="hidden" name="token" defaultValue="zSqK2ybtViuDO1L0sWTahg8pl7eYBk" />
                                                {/* <center className="px-5 mt-4">
                                                    <div className="form-group"><select className="form-control" id="selectCaptcha" name="captcha"><option value="hcaptcha">Hcaptcha</option></select></div><div id="hcaptcha" className="captcha" style={{ display: 'block' }}>
                                                        <div className="h-captcha" data-sitekey="5ebc0676-ac67-413b-91f7-8c8d978c4f6d"><iframe src="https://newassets.hcaptcha.com/captcha/v1/48ebaaf/static/hcaptcha.html#frame=checkbox&id=0jbtdl34ga5n&host=&sentry=true&reportapi=https%3A%2F%2Faccounts.hcaptcha.com&recaptchacompat=true&custom=false&hl=en&tplinks=on&sitekey=5ebc0676-ac67-413b-91f7-8c8d978c4f6d&theme=light&origin=file%3A%2F%2F" tabIndex={0} frameBorder={0} scrolling="no" title="Widget containing checkbox for hCaptcha security challenge" data-hcaptcha-widget-id="0jbtdl34ga5n" data-hcaptcha-response="Invalid Data" style={{ width: '303px', height: '78px', overflow: 'hidden' }} /><textarea id="g-recaptcha-response-0jbtdl34ga5n" name="g-recaptcha-response" style={{ display: 'none' }} defaultValue={""} /><textarea id="h-captcha-response-0jbtdl34ga5n" name="h-captcha-response" style={{ display: 'none' }} defaultValue={""} /></div></div>
                                                </center> */}
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="atb">
                                                            <div className="antibotlinks"><a href="#" rel={7391}><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC8AAAAoCAYAAABuIqMUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACjklEQVRYhe2XvWsUQRjGf8p5HHJFEFk0hX/BCnYrpogIViKiYCnYaCspLP0DhBSKoK2KWAoWfoAgJN2ABotBUkm0sBhQjhDkiMdhse9uJpuZ3dk7Pxi8B47dfeeZZ5733Xdnb6GCNEtWqrG/jVYeCnJ0xqeaNMMMuxFtG0VrPATRJ/e7Eoi2ELbx0CT+WbK+hdsaKvj7Wky4BFwG+sBIfhvAPa3MF+GcAW7K2Ng6ngZua2WW0yxZ0cosevROAguFXhM6gcaPATccQwlwC7gm1z3goIP3UeYvi3Gf3idAAUdDfO0PIQHz1vkV4ALwyDFm6y1VeA8D9dYCPQWbt+/QQCszAIZyPfbwNiu8n231mp6FScyPKrFtj17Be0be23fa6kmLeRMI6vmGxXyVH4uBITtVbauHVmYxxFQd9phip8ojD+9BmiVD8kq+B+5rZX601KtFaNuUPK1MtVIjF498CzxM/nCeB65PoLcHdhs1mhdyXYvYsSfAWRk7B1wE1mXseMNcZ9tUvdhtVGveItdVuYxpZQDeaGUWtDJbWpnvgHGsFaRXRbX/d5mvPtkW2bVYV45ND5hrbrBeHUrz1Vvi4bkqVW5taZZcTbPkVZolLwLn1uoVvhrN121JhPdo8fegN8Fc71bpS6DtS+qUI9ZU0dC28e42vsK22SpPAG+t2AHHYqHmXTyXXi1CX1ID4AOQpFnyXBboy9g3i7chx77whsAhiX2GsofvSqxJrxahlX8JvJPzOfKXTw/4Cjy1eK+BVTEzBxwh30XWgcdQtsBSoF4tyo+Rht2m4HTFTAcYa2U2PbyOmOkC21qZrWn0ghDtx/B/j2jvXLTG/wSiL0brBKLNeFrj0SZeIPoEZgjAL1P3Ew+AU05rAAAAAElFTkSuQmCC" alt="" width={47} height={40} style={{ border: '1px solid #222222', borderRadius: '5px', margin: '2px' }} /></a></div>
                                                        </div>
                                                    </div>
                                                    {/* <center> <iframe data-aa={1817833} src="//ad.a-ads.com/1817833?size=320x100" style={{ width: '320px', height: '100px', border: '0px', padding: 0, overflow: 'hidden', backgroundColor: 'transparent' }} /> </center> */}
                                                    <div className="col-md-6">
                                                        <div className="atb">
                                                            <div className="antibotlinks"><a href="#" rel={4605}><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD4AAAAoCAYAAACmTknCAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADAElEQVRoge2XP2gUQRTGf0aJlyNYXBGixUFkCBgExcJmbMZKFETFQtAgAVPYCQqCYmVlYRXQRhC1EoMgWLvVglgIF0KKMIqFRBQxxyExGgkWOxvXu/0zu+7dLXf3wfDY3ffNvm/em38wQPuhpKh1O4auoa/FDzBAPPp6enRFfJFHvG2x/U/H7R6wQiakkEEFsC2Ns5LiGHAeEEA58GkTWAEWgLuOq78mcA8CyzZcJcVt4GRCaJPAVcfV92y1DNk6KikuAXeAqUCgflsESsBx4JmSYjSGOwXMA1UbLuA/L8S0eeC6X2XNNgw7LEWXgcvAF+Ci4+qVCL8rwAxwDngQxVVS/HBcPZLENSgDm46rpxNirDmuPtBso/xtMz6JN0gvokQbPDZ2XxTXBDTSSg3lgid8LSlAX2SzjYKt8Iqx9QS/hrG7orgxAYVxwRP+3SLGVLCe411EYsaz7CDtEn4mx75GCQhXUgwpKUpKiq3Y/TmdplOrxS0DnictLilQBspKijngMN4OAICSog68Bx6m/VfbSj0P0UqKYbzk7AWOAB/4dxtrAIeAOSXF0TR9tyvjecE/JC0Ds46rWxZXJcV+4BEwC7yy7Thzxjt0JN0EXgL3I0TXHFcvAkt4VWGNTMJznL+xA+i4uuG4+qbj6tBMBha1NaCkpCiF+YUhk/C8RPt9pa2eoH/WWAqxj6cJPq9qsxXuz69KrNffU1cj8K6Od2zNwm1BhOgx4Jvj6vWEf2zBVrh/hTyhpBiP8btg7LvAuyeGH8k1pRvGTYSS4izewraUhmd9H1dSfMK7Yf0CNBAc3SFg3LQGcDp4r1ZSXAOmI7ingDdhXCWFAJ4Cv/FW+GY7irflrQMzjqtbxEdNje2Womt45boK7MG7S1eB3aaNAT+NgBuOqz8G+RPVyusY7moUd6JaWTffPwMbRuQwsNOI3gDeArfMtmYl2kZsT8FaUy+Jb4uWXhqg1OiW+I79t0jZ7UvRA/QaOlpdRSvlPOOJ7KtTorsxuIVJaGEC6Uf8AcA9ShdswJSVAAAAAElFTkSuQmCC" alt="" width={62} height={40} style={{ border: '1px solid #222222', borderRadius: '5px', margin: '2px' }} /></a></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Adzoc - Ad Display Code */}
                                                <div id="adm-container-2500" />
                                                {/* Adzoc - Ad Display Code */}
                                                <button className="btn btn-primary" >Claim Now</button>
                                                <div className="atb">
                                                    <div className="antibotlinks"><a href="#" rel={8437}><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC8AAAAoCAYAAABuIqMUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADD0lEQVRYhe2Xv2sUQRTHP4ZRUuihIMmpIGjwB2JhM4EYqykVEVIo2BgLsfIPsPAf8EcrhlSKoqCNilYyIogog4oYVMSgSJDzFIkpROIgFvs2mSx7u3PnGXNw32Z23r2Z95m3b2fmoKuuuuqqqxIt+x9BlTZbga1AH7AWmAGueWenm5pnsaGUNmeAkZzh+4D9OXNOemcH8uI1lfmiiQqgXnln94vPWuCJ2B8BL4E1wEGxDXln67Fxo+CVNquB18ApoAr0ADeA297ZgVgomeep2E54Z+8qbXYCN8V2wDs7EcOEQJSBbwbuAz+Ak8BR4AjwHBgSNx8MueqdPQ1cCWx9AFI+s6lNaTMJ1AK/aix4FDxwGKgA64Ex4JzYp4DBPChpG0GlZVGVUnjC/OL7aEIx8KukvQUc986eD4Ktz4OS9msDqHRR/QCygOzYKMXAf0knDj6avGBZqDK/vIWvi+CZUwx8Gmw3gNImHNffAtRnafPeRtvL5gPwDLigtHkP7GU+ky9agKrn2NKxTZVN6SHlnX0IPJTuCaXNbXn+BlxqAaoGbAQ+Km0q3tkZGmS+6FyBuMyHk+0Bdkh33Dv7MwMF0Ku0qWRsIdQY8FGeqxm/itKmN3WUM2RSttS/gweOSTsNXM78lrc15kEN5fjVSd5GaJtTo+xHw8u9ZY90x72zPzJZCY/1aoEtb5HXmX8bC0qnXWVzXNppFtZ6qqLMz0F5Z2dJLmwh6LasX4yibpVKmyrJLgPzWR8BJoA3KZTSZobkNE4BwsyHUJ/Eb1hpMwtsAH6TJHNFW+GBUZk0zHoF2ClBm4V6AGwnuV4MBvYp4N4CwKKbbBm10mYlcEi6b4FRpU0/sEts4QcWC3WW5PqwCfhOUl5TwONwB1Pa/PLOLm/EVnollu3xYoHLb2CLdxalTQ/JWyqEilHZHg9x8NuBOyVuw97ZWolP2xVT8zXgnbR1klPzkzzXgHrev5+o4BHZXbIqOj2zfovBE61mgdq6gCWVjVZglsQC/iVEdu6OSVLHgreiPNCOgO9Y8K66WkT9Ac2SN4vjrAakAAAAAElFTkSuQmCC" alt="" width={47} height={40} style={{ border: '1px solid #222222', borderRadius: '5px', margin: '2px' }} /></a></div>
                                                </div>
                                                <div className="atb">
                                                    <div className="antibotlinks" />                                          </div>
                                            </form>
                                        </div>

                                        <div className="col-span-6 md:col-span-3 xl:col-span-2 text-center">
                                            <center>
                                            </center>
                                        </div>
                                    </div>
                                    {/* <center className="m-5">
                                        <iframe allowTransparency="true" scrolling="no" frameBorder={0} framespacing={0} width={728} height={90} src="about:blank" />
                                        <br />
                                        <center> <iframe data-aa={1877700} src="//ad.a-ads.com/1877700?size=970x250" style={{ width: '970px', height: '250px', border: '0px', padding: 0, overflow: 'hidden', backgroundColor: 'transparent' }} /> </center>                          </center> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* END: Content */}
            </div>
            <div className="modal fade" id="task" tabIndex={-1} role="dialog" aria-labelledby="taskLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content text-center">
                        <a data-tw-dismiss="modal" href="javascript:;">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="x" data-lucide="x" className="lucide lucide-x w-8 h-8 text-slate-400"><line x1={18} y1={6} x2={6} y2={18} /><line x1={6} y1={6} x2={18} y2={18} /></svg>
                        </a>
                        <div className="modal-body">
                            <h3 className="modal-title rounded" id="taskLabel"><b>Daily Task</b></h3>
                            <div className="intro-y alert alert-primary show flex" role="alert">Progress will refresh at 00:00 UTC daily</div>                <div className="table-responsive">
                                <table className="table table-centered">
                                    <thead>
                                        <tr>
                                            <th>Task</th>
                                            <th>Reward</th>
                                            <th>Progress</th>
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Complete 5 shortlinks</td>
                                            <td>
                                                <div className="text-primary fw-bold"> +0.0005 USD</div>
                                                <div className="text-pending fw-bold"> +50 Energy</div>
                                            </td>
                                            <td>
                                                <div className="progress" style={{ height: '20px' }}>
                                                    <div className="progress-bar" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>0/ 5</div>
                                                </div>
                                            </td>
                                            <td>
                                                <form action="https://faucetuno.com/achievements/claim/1" method="POST">
                                                    <input type="hidden" name="csrf_token_name" defaultValue="18bf7e051e390de6cf6bf760ceab1fa5" />
                                                    <button type="submit" className="btn rounded btn-primary" disabled>Claim</button>
                                                </form>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Complete 10 shortlinks</td>
                                            <td>
                                                <div className="text-primary fw-bold"> +0.001 USD</div>
                                                <div className="text-pending fw-bold"> +100 Energy</div>
                                            </td>
                                            <td>
                                                <div className="progress" style={{ height: '20px' }}>
                                                    <div className="progress-bar" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>0/ 10</div>
                                                </div>
                                            </td>
                                            <td>
                                                <form action="https://faucetuno.com/achievements/claim/2" method="POST">
                                                    <input type="hidden" name="csrf_token_name" defaultValue="18bf7e051e390de6cf6bf760ceab1fa5" />
                                                    <button type="submit" className="btn rounded btn-primary" disabled>Claim</button>
                                                </form>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Complete 20 shortlinks</td>
                                            <td>
                                                <div className="text-primary fw-bold"> +0.002 USD</div>
                                                <div className="text-pending fw-bold"> +100 Energy</div>
                                            </td>
                                            <td>
                                                <div className="progress" style={{ height: '20px' }}>
                                                    <div className="progress-bar" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>0/ 20</div>
                                                </div>
                                            </td>
                                            <td>
                                                <form action="https://faucetuno.com/achievements/claim/3" method="POST">
                                                    <input type="hidden" name="csrf_token_name" defaultValue="18bf7e051e390de6cf6bf760ceab1fa5" />
                                                    <button type="submit" className="btn rounded btn-primary" disabled>Claim</button>
                                                </form>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Complete 10 manual claims</td>
                                            <td>
                                                <div className="text-primary fw-bold"> +0.0005 USD</div>
                                                <div className="text-pending fw-bold"> +50 Energy</div>
                                            </td>
                                            <td>
                                                <div className="progress" style={{ height: '20px' }}>
                                                    <div className="progress-bar" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>0/ 10</div>
                                                </div>
                                            </td>
                                            <td>
                                                <form action="https://faucetuno.com/achievements/claim/4" method="POST">
                                                    <input type="hidden" name="csrf_token_name" defaultValue="18bf7e051e390de6cf6bf760ceab1fa5" />
                                                    <button type="submit" className="btn rounded btn-primary" disabled>Claim</button>
                                                </form>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Complete 20 manual claims</td>
                                            <td>
                                                <div className="text-primary fw-bold"> +0.001 USD</div>
                                                <div className="text-pending fw-bold"> +100 Energy</div>
                                            </td>
                                            <td>
                                                <div className="progress" style={{ height: '20px' }}>
                                                    <div className="progress-bar" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>0/ 20</div>
                                                </div>
                                            </td>
                                            <td>
                                                <form action="https://faucetuno.com/achievements/claim/5" method="POST">
                                                    <input type="hidden" name="csrf_token_name" defaultValue="18bf7e051e390de6cf6bf760ceab1fa5" />
                                                    <button type="submit" className="btn rounded btn-primary" disabled>Claim</button>
                                                </form>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Complete 30 manual claims</td>
                                            <td>
                                                <div className="text-primary fw-bold"> +0.002 USD</div>
                                                <div className="text-pending fw-bold"> +100 Energy</div>
                                            </td>
                                            <td>
                                                <div className="progress" style={{ height: '20px' }}>
                                                    <div className="progress-bar" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>0/ 30</div>
                                                </div>
                                            </td>
                                            <td>
                                                <form action="https://faucetuno.com/achievements/claim/6" method="POST">
                                                    <input type="hidden" name="csrf_token_name" defaultValue="18bf7e051e390de6cf6bf760ceab1fa5" />
                                                    <button type="submit" className="btn rounded btn-primary" disabled>Claim</button>
                                                </form>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Complete 20 Offerwalls</td>
                                            <td>
                                                <div className="text-primary fw-bold"> +0.001 USD</div>
                                                <div className="text-pending fw-bold"> +100 Energy</div>
                                            </td>
                                            <td>
                                                <div className="progress" style={{ height: '20px' }}>
                                                    <div className="progress-bar" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>0/ 20</div>
                                                </div>
                                            </td>
                                            <td>
                                                <form action="https://faucetuno.com/achievements/claim/8" method="POST">
                                                    <input type="hidden" name="csrf_token_name" defaultValue="18bf7e051e390de6cf6bf760ceab1fa5" />
                                                    <button type="submit" className="btn rounded btn-primary" disabled>Claim</button>
                                                </form>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Withdrawal/>   */}

            {/* BEGIN: JS Assets*/}
            {/* END: JS Assets*/}

        </div>
    )
}

export default Claim