import React, { useEffect, useRef, useState, useContext } from 'react'
import { GlobalContext } from "../Globalstate";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import { f, database, storage, auth } from "../config";
import db from "../config";
import firebase from 'firebase';
import emailjs from "emailjs-com";
import axios from "axios"
import Sidenav from './Nav/Sidenav';
import Nav from './Nav/Nav';

function Withdrawal() {
  const [stage2, setstage2] = useState(false)
  const [Amount, setAmount] = useState("")
  const [Coinequivalent, setCoinequivalent] = useState("")
  const [coin, setcoin] = useState("")
  const [wallet, setwallet] = useState("")
  const [loading, setloading] = useState(true)
  const navigate = useNavigate()
  const [{ userdetails, loggedin, tradingpair, selectedinvestment }, dispatch] = useContext(GlobalContext);

  useEffect(() => {
    if (loggedin) {
      console.log(userdetails);
      console.log(userdetails.email);
      setloading(false)
    } else {
      f.auth().onAuthStateChanged(function (user) {
        if (user) {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          fetchuserdata(userids);
          setloggedin(true);
        } else {
          setloggedin(false);
          setloading(false)
          navigate("/");
        }
      });
    }

    let myDate = new Date();
    console.log(myDate.getTime())
    console.log(addHoursToDate(myDate, 1).getTime())
    console.log(myDate)
    console.log(addHoursToDate(myDate, 1))

  }, []);

  function addHoursToDate(date, hours) {
    return new Date(new Date(date).setHours(date.getHours() + hours));
  }

  const fetchuserdata = async (userid) => {
    var docRef = db.collection("users").doc(userid);
    const fetching = await docRef
      .onSnapshot((function (doc) {
        if (doc.exists) {
          setdetails(doc.data());
          console.log(doc.data())
          setloading(false)
        } else {
          console.log("No such document!");
        }
      })
      )

  };

  const setdetails = (data) => {
    dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
    dispatch({ type: "setloggedin", snippet: data });
  };

  const [open, setOpen] = React.useState(false);

  const updateUserBalance = async (bal) => {
    const newEarings = parseFloat(userdetails.balance) - parseFloat(Amount);
    var userid = f.auth().currentUser;
    var userids = userid.uid;
    var washingtonRef = await db.collection("users").doc(userids);
    washingtonRef
      .update({
        balance: newEarings,
      })
      .then(function () {
        console.log("Document successfully up2dated!");
        // alert("withdrawal was successful we will get back to you");
        Swal.fire({
          icon: 'success',
          title: 'withdrawal was successful we will get back to you!',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
    // fetchuserdata();
  };
  const CreatWithdrawalObj = async () => {
    var userid = f.auth().currentUser;
    var userids = userid.uid;
    const d1 = new Date();
    let uobj = {
      fullname: userdetails.fullname,
      email: userdetails.email,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      userid: userids,
      info: {
        amt: Amount,
        date: d1.getTime(),
        mode: "withdrawal",
        wallet: wallet,
        coin: coin,
        status: "pending",

      }
    };
    const userdetail1 = await db
      .collection("withdrawals")
      .add(uobj)
      .then(function () {
        console.log("Document successfully written!");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };
  const updatewithdrawalhistory = async () => {

    if (coin == "" || Amount == "" || wallet == "") {
      console.log("loss")
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Select wallet, investment , and Amount to procecced withdrawal",
        footer: '<a href="">Why do I have this issue?</a>'
      })
      return 0
    }
    // console.log(cointype)
    setloading(true)
    const newBal = parseFloat(userdetails.balance); /*- parseFloat(Amount);*/
    const newEarings = parseFloat(userdetails.balance) - parseFloat(Amount);
    var userid = f.auth().currentUser;
    var userids = userid.uid;
    const d1 = new Date();
    const increment = firebase.firestore.FieldValue.increment(parseInt(Amount));
    var washingtonRef = db.collection("users").doc(userids);
    await washingtonRef.update({

      Totalwithdrawal: increment,
      withdrawalhistory: firebase.firestore.FieldValue.arrayUnion({
        amt: Amount,
        date: d1.getTime(),
        mode: "withdrawal",
        wallet: wallet,
        coin: coin,
        status: "pending",
      }),
    });
    await updateUserBalance()
    await CreatWithdrawalObj()
  };
  return (
    <div>
      <Helmet>
        <script src="https://faucetuno.com/dist/js/app.js"></script>
        <script src="https://faucetuno.com/assets/jquery.min.js"></script>
        <script src='https://faucetuno.com/assets/js/webjs/sweetalert.min.js'></script>
        {/* <script src="https://faucetuno.com/assets/js/webjs/captcha.js"></script> */}
      </Helmet>

      {/* END: Head */}
      <div id="abb">
        <ins className="surfe-be" data-sid={1} />
      </div>
      {/* BEGIN: Mobile Menu */}
      <Nav />      {/* END: Mobile Menu */}
      <div className="flex overflow-hidden">
        {/* BEGIN: Side Menu */}
        <Sidenav />           {/* END: Side Menu */}
        {/* BEGIN: Content */}
        <div className="content">
          {/* BEGIN: Top Bar */}
          <div className="top-bar -mx-4 px-4 md:mx-0 md:px-0">
            {/* BEGIN: Breadcrumb */}
            <nav aria-label="breadcrumb" className="-intro-x mr-auto hidden sm:flex">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="#">hivenodes.cloud</a></li>
                <li className="breadcrumb-item active" aria-current="page">Manual Claim</li>
              </ol>
            </nav>
            {/* END: Breadcrumb */}
            {/* BEGIN: Account Menu */}
            <div className="intro-x dropdown w-8 h-8">
              <div className="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in" role="button" aria-expanded="false" data-tw-toggle="dropdown">
                <img alt="Default Avatar" src="https://faucetuno.com/assets/images/avatar.png" />
              </div>

            </div>
            {/* END: Account Menu */}
          </div>
          {/* END: Top Bar */}
          <div className="modal-body">
            <h3 className="modal-title rounded" id="withdrawLabel"><b>Withdraw Account Balance</b></h3>
            <div className="box p-5 mt-3">
              <p><b>BTC Withdraw to MiningPay</b></p>
              <p><b>Minimum MiningPay Withdraw is: 0.01 USD</b></p>
              <form id="withdrawal_modal">
                <input type="hidden" name="csrf_token_name" id="token" defaultValue="18bf7e051e390de6cf6bf760ceab1fa5" />
                <input type="hidden" name="token" defaultValue="zSqK2ybtViuDO1L0sWTahg8pl7eYBk" />
                <div className="form-group">
                  <input type="number" onChange={(e) => { setAmount(e.target.value) }} className="form-control" name="amount" min="0.01" step="0.000001" defaultValue={0} required />
                </div>
                <div className="form-group">
                  <input type="text" onChange={(e) => { setwallet(e.target.value) }} className="form-control" placeholder="BTC Wallet Address" required />
                </div>
                {/* <div className="form-group mt-2 mb-3">
                            <select className="form-control" name="currency" onChange={(e) => {console.log(e.target.value); setcoin(e.target.value) }} required>
                                <option selected disabled>Select Currency</option>
                                <option value="USDT">USDT  (1 USDT = $0.995992)</option>
                                <option value="BTC">BTC  (1 BTC = $21,165)</option>
                                <option value="BNB">BNB  (1 BNB = $301.63)</option>
                                <option value="LTC">LTC  (1 LTC = $86.8)</option>
                            </select>
                        </div> */}
                <button type="button" onClick={updatewithdrawalhistory} className="btn btn-primary">Withdraw</button>
              </form>
            </div>
          </div>
        </div>
        {/* END: Content */}
      </div>

      {/* <Withdrawal/>   */}

      {/* BEGIN: JS Assets*/}
      {/* END: JS Assets*/}

    </div>
  )
}

export default Withdrawal