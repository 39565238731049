import React, { useContext, useState, useEffect } from "react";
import { BrowserRouter, Link, Route, Routes, Switch } from "react-router-dom";
import { GlobalState } from "./Globalstate";
import AppContainer from "./AppContainer";
import Dashboard from "./Dashbord/Dashboard";
import Claim from "./Dashbord/Claim";
import Deposit from "./Dashbord/Deposit";
import Leaderboard from "./Dashbord/Leaderboard";
import Withdrawal from "./components/Withdrawal";


function App() {

    return (
        <GlobalState>
            {/* <ToastContainer className="foo" /> */}
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={<AppContainer />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/Claim" element={<Claim />} />
                    <Route path="/Deposit" element={<Deposit />} />
                    <Route path="/Withdrawal" element={<Withdrawal />} />
                    <Route path="/leaderboard" element={<Leaderboard />} />
                </Routes>
            </BrowserRouter>
        </GlobalState>
    );
}

export default App;
