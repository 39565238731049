import firebase from 'firebase';
const firebaseConfig = {
  apiKey: "AIzaSyBk1QMTshkh_nivpKhgSUs1m_rESlbE3sc",
  authDomain: "hiverigs-e6e6f.firebaseapp.com",
  databaseURL: "https://hiverigs-e6e6f-default-rtdb.firebaseio.com",
  projectId: "hiverigs-e6e6f",
  storageBucket: "hiverigs-e6e6f.appspot.com",
  messagingSenderId: "359813773824",
  appId: "1:359813773824:web:02d6024a720cae8c2c36e5"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.firestore().settings({ timestampsInSnapshots: true })

export const f = firebase;
export const database = firebase.database();
export const storage = firebase.storage();
export const auth = firebase.auth();
export default firebase.firestore()