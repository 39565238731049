import React from 'react'

function Sidenav() {
    return (
        <nav className="side-nav">
            <a href className="intro-x flex items-center pl-5 pt-4 mt-3">
                <img alt="FaucetUNO.com" className="w-6" src="https://faucetuno.com/assets/upload/logo/794067fc5ffc697cb93838c205cc2f67.png" />
                <span className="hidden xl:block text-white text-lg ml-3"> hivenodes.cloud </span>
            </a>
            <div className="side-nav__devider my-6" />
            <ul>
                <li>
                    <a href="/Dashboard" className="side-menu side-menu--active">
                        <div className="side-menu__icon"> <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="home" data-lucide="home" className="lucide lucide-home"><path d="M3 9l9-7 9 7v11a2 2 0 01-2 2H5a2 2 0 01-2-2z" /><polyline points="9 22 9 12 15 12 15 22" /></svg> </div>
                        <div className="side-menu__title"> Dashboard </div>
                    </a>
                </li>
                <li>
                    <a href="/Claim" className="side-menu ">
                        <div className="side-menu__icon"> <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="box" data-lucide="box" className="lucide lucide-box"><path d="M21 16V8a2 2 0 00-1-1.73l-7-4a2 2 0 00-2 0l-7 4A2 2 0 003 8v8a2 2 0 001 1.73l7 4a2 2 0 002 0l7-4A2 2 0 0021 16z" /><polyline points="3.27 6.96 12 12.01 20.73 6.96" /><line x1={12} y1="22.08" x2={12} y2={12} /></svg> </div>
                        <div className="side-menu__title"> Mining Timer </div>
                    </a>
                </li>
                {/* <li>
            <a href="/ads" className="side-menu ">
                <div className="side-menu__icon"> <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="globe" data-lucide="globe" className="lucide lucide-globe"><circle cx={12} cy={12} r={10} /><line x1={2} y1={12} x2={22} y2={12} /><path d="M12 2a15.3 15.3 0 014 10 15.3 15.3 0 01-4 10 15.3 15.3 0 01-4-10 15.3 15.3 0 014-10z" /></svg> </div>
                <div className="side-menu__title"> Surf Ads </div>
            </a>
        </li>
        <li>
            <a href="/links" className="side-menu ">
                <div className="side-menu__icon"> <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="link" data-lucide="link" className="lucide lucide-link"><path d="M10 13a5 5 0 007.54.54l3-3a5 5 0 00-7.07-7.07l-1.72 1.71" /><path d="M14 11a5 5 0 00-7.54-.54l-3 3a5 5 0 007.07 7.07l1.71-1.71" /></svg> </div>
                <div className="side-menu__title"> Shortlinks </div>
            </a>
        </li>
        <li>
            <a href="/wheel" className="side-menu ">
                <div className="side-menu__icon"> <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="gift" data-lucide="gift" className="lucide lucide-gift"><polyline points="20 12 20 22 4 22 4 12" /><rect x={2} y={7} width={20} height={5} /><line x1={12} y1={22} x2={12} y2={7} /><path d="M12 7H7.5a2.5 2.5 0 010-5C11 2 12 7 12 7z" /><path d="M12 7h4.5a2.5 2.5 0 000-5C13 2 12 7 12 7z" /></svg> </div>
                <div className="side-menu__title"> Wheel of fortunes </div>
            </a>
        </li>
        <li>
            <a href="/offerwalls" className="side-menu ">
                <div className="side-menu__icon"> <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="pocket" data-lucide="pocket" className="lucide lucide-pocket"><path d="M4 3h16a2 2 0 012 2v6a10 10 0 01-10 10A10 10 0 012 11V5a2 2 0 012-2z" /><polyline points="8 10 12 14 16 10" /></svg> </div>
                <div className="side-menu__title"> Offerwalls </div>
            </a>
        </li>
        <li>
            <a href="javascript:;" data-tw-toggle="modal" data-tw-target="#task" className="side-menu">
                <div className="side-menu__icon"> <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="award" data-lucide="award" className="lucide lucide-award"><circle cx={12} cy={8} r={7} /><polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88" /></svg> </div>
                <div className="side-menu__title"> Daily Tasks </div>
            </a>
        </li> */}
                <li>
                    <a href="/leaderboard" className="side-menu ">
                        <div className="side-menu__icon"> <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="list" data-lucide="list" className="lucide lucide-list"><line x1={8} y1={6} x2={21} y2={6} /><line x1={8} y1={12} x2={21} y2={12} /><line x1={8} y1={18} x2={21} y2={18} /><line x1={3} y1={6} x2="3.01" y2={6} /><line x1={3} y1={12} x2="3.01" y2={12} /><line x1={3} y1={18} x2="3.01" y2={18} /></svg> </div>
                        <div className="side-menu__title"> Leaderboard </div>
                    </a>
                </li>
                <li>
                    <a href="/Deposit" className="side-menu ">
                        <div className="side-menu__icon"> <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="credit-card" data-lucide="credit-card" className="lucide lucide-credit-card"><rect x={1} y={4} width={22} height={16} rx={2} ry={2} /><line x1={1} y1={10} x2={23} y2={10} /></svg> </div>
                        <div className="side-menu__title"> Deposit </div>
                    </a>
                </li>
                {/* <li>
            <a href="javascript:;" data-tw-toggle="modal" data-tw-target="#withdraw" className="side-menu">
                <div className="side-menu__icon"> <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="send" data-lucide="send" className="lucide lucide-send"><line x1={22} y1={2} x2={11} y2={13} /><polygon points="22 2 15 22 11 13 2 9 22 2" /></svg> </div>
                <div className="side-menu__title"> Withdraw </div>
            </a>
        </li> */}
                <li>
                    <a href="/Withdrawal" className="side-menu">
                        <div className="side-menu__icon"> <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" icon-name="send" data-lucide="send" className="lucide lucide-send"><line x1={22} y1={2} x2={11} y2={13} /><polygon points="22 2 15 22 11 13 2 9 22 2" /></svg> </div>
                        <div className="side-menu__title"> Withdraw </div>
                    </a>
                </li>
            </ul>
        </nav>
    )
}

export default Sidenav